import React from 'react'
import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import { isUndefined } from 'ramdasauce'

import { CaseStudyView } from '@views'

import { Seo } from '@atoms'

export const Head = ({ data }) => {
  const seoImageSrc = getSrc(data?.contentfulCaseStudyPage?.heroPhoto)
  const formattedSeoImage = !isUndefined(seoImageSrc) && 'https:' + seoImageSrc
  return (
    <Seo
      title={data?.contentfulCaseStudyPage?.title}
      image={formattedSeoImage}
    />
  )
}

const CaseStudy = (props) => {
  return <CaseStudyView props={props} />
}

export const caseStudyQuery = graphql`
  query caseStudyQuery($caseStudyId: String!) {
    contentfulCaseStudyPage(id: { eq: $caseStudyId }) {
      id
      title
      accentColor
      coverDescription
      coverImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          formats: [AVIF, WEBP, AUTO]
          aspectRatio: 1.5695
        )
        title
      }
      heroPhoto {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 100
          formats: [AVIF, WEBP, AUTO]
          aspectRatio: 1.751216
        )
        title
      }
      layout {
        ... on ContentfulCenteredParagraph {
          id
          __typename
          text {
            raw
          }
        }
        ... on ContentfulFullBleedImage {
          id
          __typename
          title
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              formats: [AVIF, WEBP, AUTO]
            )
            title
          }
        }
        ... on ContentfulTextSectionWBackground {
          id
          __typename
          backgroundColor
          textColor
          title
          text {
            raw
          }
        }
        ... on ContentfulCenteredImage {
          id
          __typename
          title
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              formats: [AVIF, WEBP, AUTO]
            )
            title
          }
        }
        ... on ContentfulVideo {
          id
          __typename
          styling
          vimeoId
        }
      }
    }
  }
`

export default CaseStudy
